body {
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  color: #9e9ea4;
  font-family: "Montserrat", sans-serif;
}



.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
  margin-right: 10px; /* Adjust spacing between icon and text */
}

.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
  margin-right: 5px;
  width: 26px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.close_icon {
  color: #eefffb;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
  display: none; /* Hide by default */
}

@media (max-width: 1024px) {
  .close_icon {
    display: inline; /* Show on screens smaller than 1024px */
    cursor: pointer;
  }
}


.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: 100vh;
}

/* Header  */

.header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;

  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25); /* Slightly increase opacity */
  z-index: 10; /* Ensure header is above other elements */
}

.menu-icon {
  display: none;
}


.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-icon {
  color: #19ca9f;
}
/* End Header  */

/* Sidebar  */
#sidebar {
  grid-area: sidebar;
  height: 200%;
  background: #1A1A1A;
  /* overflow-y: auto; */
  overflow: hidden ;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 30px;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 0px;
  margin-bottom: 30px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
  text-align: left;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  margin-top: 15px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
  text-align: left;
  color: #cd6db8; /* Ensures text is white */
}

.sidebar-brand span {
  color: #19ca9f; /* Ensures "ROOM" is also white */
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 12px;
  gap: 8px;
  border-radius: 4px;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.sidebar-list-item.active,
.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}



.sidebar-list-item > a {
  /* font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
  text-decoration: none;
  color: #ffffff; */

  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}


.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}

/* End Sidebar  */

/* Main  */
.main-container {
  grid-area: main;
  /* overflow-y: auto; */
  /* overflow-y: hidden ; */
  padding: 20px 20px;
  background: #F3F3F3;
  ;
  color: rgba(255, 255, 255, 0.95);
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 0px #00000040;
}
.cardS {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 0px #00000040;
  background: #FFFFFF;

  width: 565px;
height: 335px;
top: 272px;
margin-left: 200px;
gap: 0px;
align-items: center;
border: 0.3px 0px 0px 0px;
opacity: 0px;

}

.card:first-child {
  background-color:#FFFFFF;
  ;
}

.card:nth-child(2) {
  background-color: #FFFFFF;
}

.card:nth-child(3) {
  background-color: #FFFFFF;
}

.card:nth-child(4) {
  background-color: #FFFFFF;
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #19ca9f;
  font-family: "SF Pro Display";
  font-weight: 700;
  line-height: 47.73px;
  text-align: left;
}

.card-inner > .card_icon {
  font-size: 25px;
  color: #4D4D4D;
}

.card-text {
  color: #4D4D4D;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

/* Add this CSS to your stylesheet */
.highlight {
  background-color: yellow;
}



/* End Main  */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */

@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}
